import {
  memo, ReactNode, useCallback, useMemo,
} from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  ArrowLeftStartOnRectangleIcon,
  UserCircleIcon,
  Cog6ToothIcon,
} from '@heroicons/react/24/outline';

import { Badge, Tooltip } from 'flowbite-react';
import { LoadingIndicator } from '../../LoadingIndicator';

import logo from '../../../assets/logo/hoshii.svg';
import { globalUser } from '../../../state/globalUser';
import { BluePinkyBackground } from '../../background/BluePinkyBackground';
import { ROUTES } from '../../../config/routes';
import { Sidebar } from './Sidebar';
import { NotificationBell } from './NotificationBell';
import { ChatWithAdam } from './ChatWithAdam';

interface Props {
  isLoading?: boolean;
  hideMenu?: boolean;
  hideHeader?: boolean;
  contentWithPadding?: boolean;
  contentWithBorder?: boolean;

  isBeta?: boolean; // TODO: remove

  className?: string;

  children?: ReactNode;
}

const HoshiiLogo = memo(({ hideMenu }: { hideMenu?: boolean }) => (hideMenu ? (
  <div className="flex flex-row items-center gap-smd pr-sm">
    <NavLink to="/">
      <img
        className="relative mr-2 h-[30.4px] w-[91px]"
        src={logo}
        alt=""
        style={{
          filter:
            'brightness(0) saturate(100%) invert(12%) sepia(18%) saturate(5372%) hue-rotate(193deg) brightness(97%) contrast(96%)',
        }}
      />
    </NavLink>
  </div>
) : (
  <div className="flex flex-row items-center gap-smd pr-lg">
    <NavLink to="/">
      <img
        className="relative mr-2 h-[30.4px] w-[91px]"
        src={logo}
        alt=""
        style={{
          filter:
            'brightness(0) saturate(100%) invert(12%) sepia(18%) saturate(5372%) hue-rotate(193deg) brightness(97%) contrast(96%)',
        }}
      />
    </NavLink>
  </div>
)));

const BetaBadge = memo(({ isBeta }: { isBeta?: boolean }) => (isBeta ? (
  <Tooltip
    content="This feature is currently in development"
    // eslint-disable-next-line react/style-prop-object
    style="light"
    arrow={false}
  >
    <Badge color="purple">Preview</Badge>
  </Tooltip>
) : null));

const Header = memo(({
  hideHeader,
  hideMenu,
  isLoading,
  isBeta,
  isSigninIncluded,
  onSignoutButtonClick,
}: {
  hideHeader?: boolean;
  hideMenu?: boolean;
  isLoading?: boolean;
  isBeta?: boolean;
  isSigninIncluded?: boolean;
  onSignoutButtonClick?: () => void;
}) => {
  if (hideHeader) return null;

  return (
    <header className="flex w-screen flex-row items-center justify-between border-b-[1px] py-sm pl-4 pr-lg shadow-sm">
      <HoshiiLogo hideMenu={hideMenu} />

      <LoadingIndicator isLoading={isLoading} />

      <BetaBadge isBeta={isBeta} />

      <div className="mr-2 flex flex-1 items-center justify-end gap-smd">
        <ChatWithAdam />

        <NotificationBell />

        {!isSigninIncluded && !hideMenu ? (
          <NavLink
            to={ROUTES.SETTINGS}
            className="relative flex cursor-pointer flex-row items-center gap-smd rounded-md p-smd text-sm leading-6 text-gray-600 hover:bg-gray-50"
          >
            <Cog6ToothIcon
              width={24}
              height={24}
              style={{ stroke: 'rgba(107, 114, 128, 1)' }}
            />
          </NavLink>
        ) : null}

        {!isSigninIncluded ? (
          <Tooltip
            // eslint-disable-next-line react/style-prop-object
            style="light"
            arrow={false}
            content={(
              <>
                {globalUser.isSignedIn ? (
                  <button
                    type="button"
                    onClick={onSignoutButtonClick}
                    className="flex flex-row items-center gap-smd text-sm leading-6 text-gray-600"
                  >
                    <ArrowLeftStartOnRectangleIcon
                      width={22}
                      height={22}
                      style={{ stroke: 'rgba(107, 114, 128, 1)' }}
                    />
                    Sign out
                  </button>
                ) : null}
                {!globalUser.isSignedIn ? (
                  <NavLink
                    to="/signin"
                    className="flex flex-row items-center gap-smd text-sm leading-6 text-gray-600"
                  >
                    <UserCircleIcon
                      width={22}
                      height={22}
                      style={{ stroke: 'rgba(107, 114, 128, 1)' }}
                    />
                    Sign in
                  </NavLink>
                ) : null}
              </>
            )}
          >
            <div className="relative flex cursor-pointer items-center gap-smd rounded-md p-smd hover:bg-gray-50">
              <UserCircleIcon
                width={24}
                height={24}
                style={{ stroke: 'rgba(107, 114, 128, 1)' }}
              />
              <div className="text-sm text-gray-600">
                {globalUser.firstName}
              </div>
            </div>
          </Tooltip>
        ) : null}
      </div>
    </header>
  );
});

// TODO: centralize navigation
const Page = observer(
  ({
    isLoading,
    hideMenu,
    hideHeader,
    contentWithBorder,
    contentWithPadding,
    isBeta,
    children,
  }: Props) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const isSigninIncluded = useMemo(() => pathname.includes('/signin'), [pathname]);

    const onSignoutButtonClick = useCallback(() => {
      globalUser.signOut();
    }, []);

    return (
      <div className="flex h-screen w-screen flex-col">
        <Header
          isLoading={isLoading}
          hideMenu={hideMenu}
          hideHeader={hideHeader}
          isBeta={isBeta}
          isSigninIncluded={isSigninIncluded}
          onSignoutButtonClick={onSignoutButtonClick}
        />

        <div className="flex w-screen flex-1 flex-row overflow-hidden">
          {!hideMenu && <Sidebar navigate={navigate} pathname={pathname} />}

          <div
            className={`${!hideHeader && 'bg-gray-50/80 backdrop-blur-md'} relative flex h-full flex-1 overflow-hidden bg-opacity-60`}
          >
            <BluePinkyBackground parentClassName="opacity-40" />
            {contentWithBorder || contentWithPadding ? (
              <div
                className={`mx-lg mb-lg mt-md flex flex-1 flex-col overflow-hidden ${contentWithBorder ? 'rounded-lg border shadow-md' : ''}`}
              >
                {children}
              </div>
            ) : (
              children
            )}
          </div>
        </div>
      </div>
    );
  },
);

export { Page };
