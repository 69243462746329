/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-closing-tag-location */
import { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { LineChart } from '@mantine/charts';

import { Chats } from '../state/classes/Chats';
import { HStack } from '../components/ui/HStack';
import { OrderDrafts } from '../state/classes/OrderDrafts';
import { MessageIntents } from '../state/classes/MessageIntents';
import { Page } from '../components/layout/Page/Page';
import { genericErrorFeedback } from '../helpers/errors';
import { globalUser } from '../state/globalUser';
import { Card } from '../components/common/Card';
import {
  AdamInsights,
} from '../components/Dashboard';
import IntentCard from '../components/Dashboard/IntentCard/IntentCard';

import { MessageIntent } from '../models/Message';
import { Inbox } from '../components/Dashboard/Inbox';

interface Props {
  orderDrafts: OrderDrafts;
  chats: Chats;
  messageIntents: MessageIntents;
  navigateToChatsList: () => void;
  navigateToOrdersList: () => void;
  navigateToMockComplaints: () => void;
  navigateToMockAssistanceRequest: () => void;
  navigateToOrderDraftsProcessing: () => void;
}

const HomePage = observer(
  ({
    orderDrafts,
    chats,
    messageIntents,
    navigateToChatsList,
    navigateToMockAssistanceRequest,
    navigateToMockComplaints,
    navigateToOrdersList,
    navigateToOrderDraftsProcessing,
  }: Props) => {
    const isLoading = useMemo(
      () => orderDrafts.isLoading || chats.isLoading,
      [chats.isLoading, orderDrafts.isLoading],
    );

    const getOrderDrafts = useCallback(() => {
      // TODO: Remove and share the state internally in mobx
      orderDrafts.setUserId(globalUser.id);

      orderDrafts
        .loadOrderDraftsCount()
        .catch(genericErrorFeedback('Error loading order drafts'));
    }, [orderDrafts]);

    const getMessageIntents = useCallback(() => {
      messageIntents.load().catch(genericErrorFeedback('Error loading message intents'));
    }, [messageIntents]);

    const getChats = useCallback(() => {
      chats.loadCounts().catch(genericErrorFeedback('Error loading chats'));
    }, [chats]);

    const onProcessOrdersButtonClick = useCallback(() => {
      navigateToOrderDraftsProcessing();
    }, [navigateToOrderDraftsProcessing]);

    const onOrderListButtonClick = useCallback(() => {
      navigateToOrdersList();
    }, [navigateToOrdersList]);

    useEffect(() => {
      if (getChats) getChats();
    }, [getChats]);

    useEffect(() => {
      if (getOrderDrafts) getOrderDrafts();
    }, [getOrderDrafts]);

    useEffect(() => {
      if (getMessageIntents) getMessageIntents();
    }, [getMessageIntents]);

    return (
      <Page isLoading={isLoading}>
        <div className="relative flex-1 overflow-y-auto px-[10vw] pb-4">
          <div className="flex justify-between px-lg pt-10">
            <div className="flex flex-col justify-center gap-2">
              <h1 className="text-xl font-semibold leading-none text-gray-900">
                Dashboard
              </h1>
              <div className="flex items-center gap-2 text-sm font-medium text-gray-600">
                Your business at a glance
              </div>
            </div>
          </div>
          <HStack>
            <div className="h-full w-full px-lg">
              <div className="grid grid-cols-12 gap-4 pt-5">
                <Inbox
                  className="col-span-4 4xl:col-span-3 row-span-2"
                  orderCount={messageIntents.unreadCounts[MessageIntent.ORDER]}
                  otherCount={messageIntents.unreadCounts[MessageIntent.OTHER]}
                />

                <IntentCard
                  cardTitle="Unprocessed orders"
                  cardSubtitle="Track and manage your orders"
                  intentProps={[
                    {
                      cardTitle: (
                        <p className="font-medium">
                          <span className="text-base font-semibold">
                            {orderDrafts.count}
                          </span>
                          {' '}
                          Order drafts
                        </p>
                      ),
                      cardSubtitles: [
                        `${orderDrafts.count} Emails`,
                        '0 Whatsapp',
                        '0 Phone calls',
                        '0 SMS',
                      ],
                      cardData: [
                        { name: 'Unresolved', value: 200, color: 'gray.2' },
                        { name: 'Resolved', value: 400, color: 'blue.5' },
                      ],
                      buttons: [
                        {
                          title: 'Process',
                          theme: 'primary',
                          onClick: onProcessOrdersButtonClick,
                        },
                      ],
                    },
                  ]}
                  className="col-span-4 4xl:col-span-3"
                />

                <IntentCard
                  cardTitle="Unprocessed supplier notes"
                  cardSubtitle="Track and manage your supplier notes"
                  soon
                  intentProps={[
                    {
                      cardTitle: (
                        <p className="font-medium">
                          <span className="text-base font-semibold">20</span>
                          {' '}
                          Supplier note
                        </p>
                      ),
                      cardSubtitles: [
                        '14 Emails',
                        '0 Whatsapp',
                        '0 Phone calls',
                        '6 SMS',
                      ],
                      cardData: [
                        { name: 'Unresolved', value: 30, color: 'gray.2' },
                        { name: 'Resolved', value: 400, color: 'green.5' },
                      ],
                      buttons: [
                        {
                          title: 'Process',
                          theme: 'primary',
                          onClick: () => {},
                        },
                      ],
                    },
                  ]}
                  className="col-span-4 4xl:col-span-3"
                />

                <IntentCard
                  cardTitle="Q&A"
                  cardSubtitle="Track and manage your Q&A"
                  soon
                  intentProps={[
                    {
                      cardTitle: 'Product stock levels',
                      cardSubtitles: [
                        '10 Emails',
                        '0 Whatsapp',
                        '22 Phone calls',
                        '0 SMS',
                      ],
                      cardData: [
                        { name: 'Unresolved', value: 100, color: 'gray.2' },
                        { name: 'Resolved', value: 400, color: 'red.5' },
                      ],
                      buttons: [
                        {
                          title: 'Process',
                          theme: 'primary',
                          onClick: () => {},
                        },
                      ],
                    },
                    {
                      cardTitle: 'Undelivered orders',
                      cardSubtitles: [
                        '10 Emails',
                        '12 Whatsapp',
                        '0 Phone calls',
                        '5 SMS',
                      ],
                      cardData: [
                        { name: 'Unresolved', value: 200, color: 'gray.2' },
                        { name: 'Resolved', value: 20, color: 'red.5' },
                      ],
                      buttons: [
                        {
                          title: 'Process',
                          theme: 'primary',
                          onClick: () => {},
                        },
                      ],
                    },
                    {
                      cardTitle: 'Quantity of the product delivered',
                      cardSubtitles: [
                        '17 Emails',
                        '2 Whatsapp',
                        '5 Phone calls',
                        '2 SMS',
                      ],
                      cardData: [
                        { name: 'Unresolved', value: 200, color: 'gray.2' },
                        { name: 'Resolved', value: 400, color: 'red.5' },
                      ],
                      buttons: [
                        {
                          title: 'Process',
                          theme: 'primary',
                          onClick: () => {},
                        },
                      ],
                    },
                  ]}
                  className="col-span-4 4xl:col-span-3"
                />
              </div>

              <div className="mb-3 mt-6 flex w-full items-center space-x-2">
                <p className="text-sm font-semibold text-gray-800">Analytics</p>
                {/* <div className="flex-1 h-0.5 bg-gray-300" /> */}
              </div>

              <div className="relative">
                <div className="absolute left-0 top-0 h-full w-full bg-gray-50 backdrop-blur-lg opacity-40 z-[1]" />
                <div className="absolute left-0 top-0 h-full w-full flex justify-center items-center text-xl font-bold text-gray-500 z-[2]">
                  coming soon
                </div>
                <div className="blur-sm opacity-60 grid grid-cols-12 gap-4">
                  <Card className="col-span-8 row-start-1">
                    <Card.Body>
                      <h3 className="card-title mb-4 flex flex-col items-start">
                        <span className="text-sm font-semibold text-gray-800">
                          Processing time per order
                        </span>
                        <span className="mt-1 text-xs text-gray-500">
                          Average time to process an order and number of orders processed
                        </span>
                      </h3>
                      <LineChart
                        h={200}
                        data={[
                          {
                            date: 'Jan',
                            processTime: 15,
                            orderAmount: 2100,
                          },
                          {
                            date: 'Feb',
                            processTime: 13.5,
                            orderAmount: 2500,
                          },
                          {
                            date: 'Mar',
                            processTime: 12,
                            orderAmount: 2300,
                          },
                          {
                            date: 'Apr',
                            processTime: 11,
                            orderAmount: 3500,
                          },
                          {
                            date: 'May',
                            processTime: 9,
                            orderAmount: 2400,
                          },
                          {
                            date: 'June',
                            processTime: 8.9,
                            orderAmount: 3400,
                          },
                          {
                            date: 'July',
                            processTime: 7,
                            orderAmount: 4600,
                          },
                          {
                            date: 'Aug',
                            processTime: 5,
                            orderAmount: 4700,
                          },
                        ]}
                        dataKey="date"
                        series={[
                          { name: 'processTime', color: 'indigo.5', label: 'Processing time (min)' },
                          {
                            name: 'orderAmount', color: 'indigo.3', label: 'Orders', yAxisId: 'right',
                          },
                        ]}
                        yAxisProps={{
                          domain: [5, 15],
                        }}
                        rightYAxisProps={{
                          domain: [2000, 5000],
                          ticks: [2000, 3000, 4000, 5000],
                          tickFormatter(value) {
                            if (value > 1000) {
                              return `${value / 1000}k`;
                            }
                            return value;
                          },
                        }}
                        withLegend
                        withRightYAxis
                      />
                    </Card.Body>
                  </Card>

                  <AdamInsights />
                </div>
              </div>
            </div>
          </HStack>
        </div>
      </Page>
    );
  },
);

export { HomePage };
