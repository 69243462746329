import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// import { HomePage } from '../../pages/HomePage';
import { HomePage } from '../../pages/HomePageTemp';
import { ProtectedScreen } from '../../components/ui/ProtectedScreen';
import { globalChats } from '../../state/globalChats';
import { globalOrderDrafts } from '../../state/globalOrderDrafts';
import { globalMessageIntents } from '../../state/globalMessageIntents';
import { ROUTES } from '../../config/routes';

const HomeScreen = () => {
  const navigate = useNavigate();

  const navigateToChatsList = useCallback(() => {
    navigate(ROUTES.CHATS);
  }, [navigate]);

  const navigateToOrderDraftsProcessing = useCallback(() => {
    navigate(ROUTES.PROCESS_ORDER_DRAFTS);
  }, [navigate]);

  const navigateToOrdersList = useCallback(() => {
    navigate(ROUTES.ORDERS);
  }, [navigate]);

  const navigateToMockComplaints = useCallback(() => {
    navigate('/complaints');
  }, [navigate]);

  const navigateToMockAssistanceRequest = useCallback(() => {
    navigate('/assistance_request');
  }, [navigate]);

  return (
    <ProtectedScreen>
      <HomePage
        orderDrafts={globalOrderDrafts}
        chats={globalChats}
        messageIntents={globalMessageIntents}
        navigateToChatsList={navigateToChatsList}
        navigateToOrderDraftsProcessing={navigateToOrderDraftsProcessing}
        navigateToOrdersList={navigateToOrdersList}
        navigateToMockComplaints={navigateToMockComplaints}
        navigateToMockAssistanceRequest={navigateToMockAssistanceRequest}
      />
    </ProtectedScreen>
  );
};

export { HomeScreen };
